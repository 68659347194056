@import "../../styles/helpers";

.button {
    &.active {
        background: $n2;
        box-shadow: 0 0 0 2px $n2 inset;
        color: $n8;
        svg {
            fill: $n8; }
        @include dark {
            background: $n3;
            box-shadow: 0 0 0 2px $n3 inset; } } }

.body {
    position: absolute;
    top: calc(100% + 28px);
    right: 0;
    z-index: 5;
    width: 640px;
    height: 480px;
    padding: 4px;
    background: $n8;
    border-radius: 24px;
    border: 1px solid $n6;
    visibility: hidden;
    opacity: 0;
    transition: all .4s;
    @include m {
        left: -20px;
        right: -20px;
        width: auto; }
    &.show {
        visibility: visible;
        opacity: 1; }
    iframe {
        width: 100%;
        height: 100%;
        border-radius: 21px; } }

