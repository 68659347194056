@import "../../../styles/helpers";

.title {
    margin-bottom: 64px;
    @include m {
        margin-bottom: 32px;
        font-size: 32px; } }

.box {
    &:not(:last-child) {
        margin-bottom: 64px;
        padding-bottom: 64px;
        border-bottom: 1px solid $n6;
        @include m {
            margin-bottom: 32px;
            padding-bottom: 32px; }
        @include dark {
            border-color: $n3; } }
    & > .item {
        &:not(:last-child) {
            margin-bottom: 32px; } } }

.category {
    margin-bottom: 16px;
    @include body-bold-1; }

.line {
    display: flex;
    align-items: center;
    margin-bottom: 32px; }

.subtitle {
    margin-right: auto;
    @include body-bold-2; }

.cards {
    display: flex; }

.fieldset {
    & > .field {
        &:not(:last-child) {
            margin-bottom: 32px; } } }

.row {
    display: flex;
    margin: 0 -10px;
    .field {
        flex: 0 0 calc(50% - 20px);
        width: calc(50% - 20px);
        margin: 0 10px; } }

.checkbox {
    margin-top: 32px; }

.flex {
    display: flex;
    margin: 0 -48px;
    @include m {
        display: block;
        margin: 0; }
    .item {
        flex: 0 0 50%;
        padding: 0 48px;
        @include m {
            padding: 0; }
        &:first-child {
            border-right: 1px solid $n6;
            @include m {
                margin-bottom: 16px;
                border: none; }
            @include dark {
                border-color: $n3; } } }
    .button {
        @include dark {
            box-shadow: inset 0 0 0 2px $n3; } } }

.item {
    display: flex;
    align-items: center;
    @include m {
        align-items: flex-start; } }

.details {
    flex-grow: 1; }

.label {
    margin-bottom: 4px;
    font-weight: 500; }

.text {
    @include caption-2;
    color: $n4; }

.button {
    flex-shrink: 0;
    margin-left: 24px; }
