@import "../../../styles/helpers";

.head {
    margin-bottom: 92px;
    text-align: center;
    @include t {
        margin-bottom: 64px; }
    @include m {
        margin-bottom: 48px; } }

.stage {
    margin-bottom: 8px; }

.title {
    margin-bottom: 32px;
    @include m {
        font-size: 32px; } }

.list {
    display: flex;
    margin: 0 -12px;
    @include t {
        display: block; } }

.item {
    flex: 0 0 calc(33.333% - 24px);
    width: calc(33.333% - 24px);
    margin: 0 12px;
    @include t {
        width: 100%;
        margin: 0; }
    &:not(:last-child) {
        @include t {
            margin-bottom: 24px; } } }
