@import "../../styles/helpers";

.panel {
    position: relative;
    z-index: 5;
    padding: 40px;
    @include d {
        padding: 32px 32px 24px; }
    @include m {
        padding: 32px 20px 24px; } }

.background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 24px;
    box-shadow: 0px 40px 64px -32px rgba(15, 15, 15, 0.1);
    @include m {
        border-radius: 20px;
        box-shadow: 0px 16px 32px rgba(15, 15, 15, 0.1); }
    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(83.59deg, #FCFCFD 36.52%, rgba(252, 252, 253, 0.83) 98.8%);
        border: 1px solid $n8;
        backdrop-filter: blur(32px);
        border-radius: 24px;
        @include m {
            border-radius: 20px; }
        @include dark {
            background: linear-gradient(92.98deg, #23262F 3.54%, rgba(35, 38, 47, 0.7) 93.38%);
            border-color: $n3; } } }

.nav {
    position: relative;
    z-index: 2;
    display: flex;
    box-shadow: inset 0 -1px 0 0 $n6;
    @include m {
        margin: 0 -20px;
        padding: 0 20px; }
    @include dark {
        box-shadow: inset 0 -1px 0 0 rgba($n4, .3); } }

.link {
    padding-bottom: 30px;
    border-bottom: 1px solid transparent;
    @include button-2;
    color: $n4;
    transition: all .2s;
    @include d {
        padding-bottom: 24px; }
    &:hover {
        color: $n2; }
    &.active {
        border-color: $n2;
        color: $n2; }
    @include dark {
        color: $n5;
        &:hover {
            color: $n8; }
        &.active {
            border-color: $n8;
            color: $n8; } }
    &:not(:last-child) {
        margin-right: 48px;
        @include m {
            margin-right: auto; } } }

.body {
    position: relative;
    z-index: 3; }

.search {
    position: absolute;
    top: 50%;
    right: 20px;
    width: 64px;
    height: 64px;
    transform: translateY(-50%);
    background: $p1;
    border-radius: 50%;
    transition: background .2s;
    @include x {
        right: 0; }
    @include d {
        width: 56px;
        height: 56px; }
    @include t {
        top: auto;
        bottom: 2px;
        transform: translateY(0); }
    svg {
        fill: $n8; }
    &:hover {
        background: darken($p1, 5); } }
