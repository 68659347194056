@import "../../../styles/helpers";

.head {
    margin-bottom: 48px; }

.list {
    display: flex;
    flex-wrap: wrap;
    margin: -48px -16px 0;
    @include m {
        display: block;
        margin: -32px 0 0; } }

.destination {
    flex: 0 0 calc(33.333% - 32px);
    width: calc(33.333% - 32px);
    margin: 48px 16px 0;
    @include t {
        flex: 0 0 calc(50% - 32px);
        width: calc(50% - 32px); }
    @include m {
        width: 100%;
        margin: 32px 0 0; }
    &:nth-child(n+5) {
        @include m {
            display: none; } }
    &:nth-child(n+9) {
        @include t {
            display: none; }
        @include m {
            display: block; } } }

.button {
    margin-top: 48px; }
