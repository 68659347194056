@import "../../styles/helpers";

.head {
    margin-bottom: 64px;
    text-align: center;
    @include m {
        margin-bottom: 32px; } }

.category {
    display: inline-block;
    margin-bottom: 12px;
    padding: 8px 8px 6px;
    box-shadow: inset 0 0 0 2px $p4;
    border-radius: 4px;
    @include hairline-2;
    color: $p4;
    @include m {
        display: none; } }

.title {
    margin-bottom: 48px;
    @include m {
        margin-bottom: 32px; } }

.nav {
    display: flex;
    justify-content: center;
    @include m {
        display: none; } }

.link {
    display: flex;
    padding: 6px 12px;
    border-radius: 14px;
    background: none;
    @include button-2;
    color: $n4;
    transition: all .2s;
    &:hover {
        color: $n3;
        @include dark {
            color: $n6; } }
    &.active {
        background: $n3;
        color: $n8;
        @include dark {
            background: $n8;
            color: $n2; } }
    &:not(:last-child) {
        margin-right: 8px; } }

.dropdown {
    text-align: left; }

.wrapper {
    margin: 0 -12px;
    @include m {
        margin: 0 -8px; } }
