@import "../../../styles/helpers";

.item {
    display: block;
    border-radius: 24px;
    color: $n2;
    transition: color .2s;
    &:hover {
        color: $p1;
        .preview {
            img {
                transform: scale(1.1); } } }
    @include dark {
        color: $n8;
        &:hover {
            color: $p1; } } }

.preview {
    position: relative;
    margin-bottom: 20px;
    overflow: hidden;
    border-radius: 24px;
    &:before {
        content: "";
        display: block;
        padding-bottom: 100%; }
    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transition: transform 1s; } }

.category {
    position: absolute;
    top: 16px;
    left: 16px;
    z-index: 2; }

.title {
    margin-bottom: 8px;
    @include body-bold-2; }

.counter {
    display: flex;
    align-items: center;
    @include caption-bold-2;
    color: $n4;
    svg {
        margin: -2px 6px 0 0;
        fill: $n4; } }
