@import "../../styles/helpers";

.play {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 3;
    width: 80px;
    height: 80px;
    transform: translate(-50%,-50%);
    box-shadow: 0px 8px 16px -8px rgba(15, 15, 15, 0.1);
    border-radius: 50%;
    background: $n8;
    @include m {
        width: 48px;
        height: 48px; }
    svg {
        fill: $n4;
        transition: all .2s;
        @include m {
            width: 12px;
            height: 12px; } }
    &:hover {
        svg {
            transform: scale(1.2);
            fill: $p1; } } }

.small {
    width: 48px;
    height: 48px;
    svg {
        width: 12px;
        height: 12px; } }
