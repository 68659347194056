@import "../../../styles/helpers";

.title {
    margin-bottom: 48px;
    text-align: center;
    @include m {
        margin-bottom: 40px; } }

.nav {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: -12px -6px 40px;
    @include m {
        display: none; } }

.btn {
    margin: 12px 6px 0;
    padding: 6px 12px;
    border-radius: 14px;
    background: none;
    @include button-2;
    color: $n4;
    transition: all .2s;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    &:hover {
        color: $n3;
        @include dark {
            color: $n8; } }
    &.active {
        background: $n3;
        color: $n8;
        @include dark {
            background: $n8;
            color: $n2; } } }

.dropdown {
    display: none;
    text-align: left;
    @include m {
        display: block;
        margin-bottom: 24px; } }

.list {
    max-width: 546px;
    margin: 0 auto; }
