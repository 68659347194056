@import "../../styles/helpers";

.head {
    margin-bottom: 64px;
    text-align: center;
    @include m {
        margin-bottom: 48px; } }

.wrapper {
    margin: 0 -16px;
    @include m {
        margin: 0 -4px; } }

.item {
    text-align: center;
    color: $n2;
    transition: color .2s;
    &:hover {
        color: $p1;
        .preview {
            img {
                transform: scale(1.05); } } }
    @include dark {
        color: $n8;
        &:hover {
            color: $p1; } } }

.preview {
    margin-bottom: 24px;
    overflow: hidden;
    border-radius: 16px;
    img {
        width: 100%;
        transition: transform 1s; } }

.subtitle {
    margin-bottom: 8px;
    @include body-bold-2; }

.text {
    color: $n4; }

.btns {
    margin-top: 64px;
    text-align: center;
    @include t {
        margin-top: 40px; } }

.button {
    .loader {
        margin: 0 16px 0 5px;
        transform: scale(.8); } }
