@import "../../../styles/helpers";

.inner {
    @include t {
        position: relative;
        max-width: 455px;
        margin: auto;
        padding: 24px;
        border-radius: 24px;
        background: $n8; }
    @include m {
        padding: 24px 16px; }
    @include dark {
        background: $n1; } }


.title {
    margin-bottom: 40px;
    @include body-bold-1; }

.close {
    display: none;
    position: absolute;
    top: 20px;
    right: 24px;
    width: 40px;
    height: 40px;
    background: $n8;
    border: 2px solid $n6;
    border-radius: 50%;
    font-size: 0;
    @include t {
        display: inline-block; }
    svg {
        fill: $n2;
        transition: transform .2s; }
    @include dark {
        border-color: $n3;
        background: $n1;
        svg {
            fill: $n8; } } }

.card {
    box-shadow: 0px 64px 64px -48px rgba(15, 15, 15, 0.08); }
