@import "../../../styles/helpers";

.wrapper {
    margin: 0 -16px; }

.preview {
    img {
        width: 100%;
        border-radius: 16px;
        object-fit: cover;
        @include t {
            height: 600px; }
        @include m {
            height: 300px; } } }
