@import "../../styles/helpers";

.container {
    max-width: 1440px;
    @include w {
        padding: 0 40px; }
    @include m {
        padding: 0; } }

.inner {
    padding: 80px 80px 100px;
    background: $n7;
    border-radius: 24px;
    @include w {
        padding: 80px 40px; }
    @include m {
        padding: 64px 24px;
        border-radius: 0; }
    @include dark {
        background: #18191D; } }

.head {
    margin-bottom: 80px;
    text-align: center;
    @include d {
        margin-bottom: 64px; }
    @include m {
        margin-bottom: 32px; } }

.title {
    margin-bottom: 12px; }

.slider {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: -48px -10px 0;
    @include x {
        margin: -32px -16px 0; }
    @include m {
        display: block;
        margin: 0; } }

.item {
    flex: 0 0 calc(20% - 48px);
    width: calc(20% - 48px);
    margin: 48px 24px 0;
    @include x {
        flex: 0 0 calc(20% - 32px);
        width: calc(20% - 32px);
        margin: 32px 16px 0; }
    @include d {
        flex: 0 0 calc(25% - 32px);
        width: calc(25% - 32px); }
    @include t {
        flex: 0 0 calc(33.333% - 32px);
        width: calc(33.333% - 32px); }
    @include m {
        width: 100%;
        margin: 0; }
    &:nth-child(n+9) {
        @include d {
            display: none; }
        @include t {
            display: block; } } }
