@import "../../../../../styles/helpers";

.wrapper {
    margin: 0 -16px;
    @include m {
        margin: 0 -8px; } }

.slide {
    height: 100%; }

.item {
    height: 100%;
    padding: 32px;
    border-radius: 24px;
    @include d {
        padding: 24px; }
    @include dark {
        color: $n2; } }

.icon {
    margin-bottom: 64px;
    @include t {
        margin-bottom: 32px; } }

.title {
    margin-bottom: 4px;
    @include body-bold-1; }

.text {
    @include caption-2; }
