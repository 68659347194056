@import "../../styles/helpers";

.section {
    overflow: hidden; }

.head {
    max-width: 640px;
    margin: 0 auto 127px;
    text-align: center;
    @include x {
        margin-bottom: 80px; }
    @include m {
        margin-bottom: 48px;
        text-align: left; } }

.row {
    display: flex;
    align-items: center;
    @include t {
        display: block; } }

.col {
    &:first-child {
        flex-grow: 1;
        padding-right: 128px;
        @include t {
            margin-bottom: 64px;
            padding: 0; } }
    &:nth-child(2) {
        flex-shrink: 0;
        width: 454px;
        @include t {
            width: 100%; } } }

.list {
    max-width: 352px;
    margin-bottom: 40px;
    @include t {
        max-width: 100%; } }

.item {
    &:not(:last-child) {
        margin-bottom: 40px; } }

.number {
    display: inline-block;
    min-width: 38px;
    margin-bottom: 24px;
    padding: 0 8px;
    border-radius: 12px;
    text-align: center;
    font-weight: 500;
    line-height: 24px;
    color: $n8; }

.subtitle {
    margin-bottom: 16px;
    @include body-bold-1; }

.text {
    color: $n4; }

.gallery {
    position: relative;
    img {
        width: 100%; } }

.bg {
    overflow: hidden;
    border-radius: 32px;
    img {
        transition: all 1s cubic-bezier(0.7, 0, 0.3, 1); } }

.preview {
    position: absolute;
    width: 56.5%;
    filter: drop-shadow(0px 40px 64px rgba(15, 15, 15, 0.1));
    transform: scale(.8);
    opacity: 0;
    transition: all 1s cubic-bezier(0.7, 0, 0.3, 1);
    &:first-child {
        top: 40%;
        left: -18%;
        transition-delay: .3s;
        @include d {
            left: -8%; } }
    &:nth-child(2) {
        top: 48%;
        right: -17%;
        transition-delay: .6s; }
    &:nth-child(3) {
        left: -32%;
        bottom: 17%;
        transition-delay: .9s;
        @include d {
            left: -22%; }
        @include m {
            left: 5%; } } }
