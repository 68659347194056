@import "../../../styles/helpers";

.preview {
    margin-bottom: 16px;
    img {
        width: 100%;
        border-radius: 16px; } }

.title {
    margin-bottom: 12px;
    @include body-bold-1; }

.author {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    .text {
        margin-right: 4px;
        @include caption-2;
        color: $n4; } }

.avatar {
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    margin: 0 8px;
    img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover; } }

.man {
    @include caption-bold-2; }

