@import "../../../styles/helpers";

.container {
    max-width: 1440px;
    @include w {
        padding: 0 40px; }
    @include t {
        padding: 0; } }

.wrapper {
    padding: 80px;
    border-radius: 24px;
    background: $n7;
    @include w {
        padding: 64px 40px; }
    @include t {
        border-radius: 0; }
    @include m {
        padding: 64px 32px; }
    @include dark {
        background: $n1;
        box-shadow: inset 0 0 0 2px $n2; } }

.title {
    margin-bottom: 12px; }

.info {
    margin-bottom: 64px;
    @include d {
        margin-bottom: 48px; } }

.sorting {
    display: flex;
    align-items: center;
    margin-bottom: 48px;
    @include t {
        justify-content: space-between; }
    @include m {
        display: block; } }

.nav {
    display: flex;
    justify-content: center;
    margin-right: auto;
    @include t {
        display: none; } }

.link {
    display: flex;
    padding: 6px 12px;
    border-radius: 14px;
    background: none;
    @include button-2;
    color: $n4;
    transition: all .2s;
    svg {
        margin-right: 8px;
        fill: $n4;
        transition: fill .2s; }
    &:hover {
        color: $n3;
        svg {
            fill: $n3; }
        @include dark {
            color: $n6;
            svg {
                fill: $n6; } } }
    &.active {
        background: $n3;
        color: $n8;
        svg {
            fill: $n8; }
        @include dark {
            background: $n6;
            color: $n2;
            svg {
                fill: $n2; } } }
    &:not(:last-child) {
        margin-right: 16px; } }

.box {
    flex-shrink: 0;
    width: 256px;
    @include m {
        width: 100%;
        &:not(:last-child) {
            margin-bottom: 16px; } } }

.list {
    display: flex;
    flex-wrap: wrap;
    margin: -32px -16px 0;
    @include x {
        margin: -32px -10px 0; }
    @include d {
        margin: -32px -16px 0; }
    @include m {
        display: block;
        margin: 0; } }

.card {
    flex: 0 0 calc(25% - 32px);
    width: calc(25% - 32px);
    margin: 32px 16px 0;
    @include x {
        flex: 0 0 calc(25% - 20px);
        width: calc(25% - 20px);
        margin: 32px 10px 0; }
    @include d {
        flex: 0 0 calc(33.333% - 32px);
        width: calc(33.333% - 32px);
        margin: 32px 16px 0; }
    @include t {
        flex: 0 0 calc(50% - 32px);
        width: calc(50% - 32px); }
    @include m {
        display: block;
        width: 100%;
        margin: 32px 0 0; }
    &:nth-child(n+7) {
        @include d {
            display: none; } } }

.btns {
    margin-top: 64px;
    text-align: center;
    @include t {
        margin-top: 48px; } }

