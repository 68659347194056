@import "../../../styles/helpers";

.row {
    display: flex;
    margin: 0 -16px;
    @include m {
        display: block;
        margin: 0; } }

.col {
    flex: 0 0 calc(50% - 32px);
    width: calc(50% - 32px);
    margin: 0 16px;
    @include m {
        width: 100%;
        margin: 0;
        &:not(:last-child) {
            margin-bottom: 48px; } } }

.logo {
    margin-bottom: 48px;
    @include t {
        margin-bottom: 24px; }
    img {
        max-width: 166px; } }

.content {
    max-width: 450px;
    margin-bottom: 24px;
    @include body-1;
    @include t {
        font-size: 20px; }
    @include m {
        font-size: 24px; } }

.details {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 48px;
    @include caption-1;
    @include m {
        margin-bottom: 32px; } }

.icon {
    margin-right: 14px; }

.author {
    margin-right: 24px;
    font-weight: 500; }

.description {
    color: $n4;
    @include t {
        margin-left: 34px; } }

.preview {
    position: relative;
    img {
        width: 100%;
        border-radius: 16px;
        @include m {
            border-radius: 12px; } } }
