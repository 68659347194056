@import "../../../../styles/helpers";

.item {
    padding-bottom: 48px;
    border-bottom: 1px solid $n6;
    @include m {
        padding-bottom: 24px; }
    @include dark {
        border-color: $n3; }
    &:not(:last-child) {
        margin-bottom: 48px;
        @include m {
            margin-bottom: 24px; } } }

.label {
    margin-bottom: 12px;
    @include hairline-2;
    color: $n5; }

.scale {
    display: flex;
    justify-content: space-between;
    font-weight: 500; }

.option {
    &:not(:last-child) {
        margin-bottom: 32px;
        @include m {
            margin-bottom: 24px; } } }

.variants {
    display: flex;
    flex-direction: column;
    .checkbox {
        width: 100%;
        &:not(:last-child) {
            margin-bottom: 16px; } } }

.reset {
    display: inline-flex;
    align-items: center;
    margin-top: 24px;
    @include button-2;
    color: $n4;
    transition: color .2s;
    svg {
        margin-right: 8px;
        fill: $n4;
        transition: fill .2s; }
    &:hover {
        color: $n2;
        svg {
            fill: $n2; }
        @include dark {
            color: $n6;
            svg {
                fill: $n6; } } } }

