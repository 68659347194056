@import "../../../styles/helpers";

.control {
    padding: 0;
    @include m {
        display: none; } }

.sorting {
    display: flex;
    align-items: center;
    margin: 32px 0 64px;
    padding-top: 32px;
    border-top: 1px solid $n6;
    @include m {
        display: block;
        margin: 0 0 32px;
        padding-top: 0;
        border: none; }
    @include dark {
        border-color: $n3; } }

.box {
    flex-shrink: 0;
    width: 256px;
    margin-right: auto;
    @include m {
        width: 100%;
        margin: 0 0 12px; }
    .button {
        width: 100%;
        margin-top: 12px;
        &.active {
            background: $n2;
            box-shadow: inset 0 0 0 2px $n2;
            color: $n8;
            @include dark {
                background: $p1;
                box-shadow: inset 0 0 0 2px $p1; } } } }

.nav {
    display: flex;
    justify-content: center;
    @include m {
        display: none; } }

.link {
    display: flex;
    padding: 6px 12px;
    border-radius: 14px;
    background: none;
    @include button-2;
    color: $n4;
    transition: all .2s;
    svg {
        margin-right: 8px;
        fill: $n4;
        transition: fill .2s; }
    &:hover {
        color: $n3;
        svg {
            fill: $n3; }
        @include dark {
            color: $n6; } }
    &.active {
        background: $n3;
        color: $n8;
        svg {
            fill: $n8; }
        @include dark {
            background: $n8;
            color: $n2; } }
    &:not(:last-child) {
        margin-right: 8px; } }

.wrapper {
    display: flex;
    align-items: flex-start;
    @include m {
        display: block; } }

.filters {
    flex-shrink: 0;
    width: 256px;
    @include m {
        display: none;
        width: 100%;
        padding-bottom: 40px; }
    &.active {
        display: block; } }

.inner {
    flex-grow: 1;
    padding-left: 80px;
    @include x {
        padding-left: 40px; }
    @include d {
        padding-left: 32px; }
    @include m {
        margin: 0 -16px;
        padding: 0; } }

.flight {
    &:not(:last-child) {
        margin-bottom: 24px; } }

.btns {
    margin-top: 40px;
    text-align: center;
    @include m {
        margin-top: 32px; } }

.button {
    .loader {
        margin: 0 20px 0 4px; } }
