@import "../../../../../styles/helpers";

.category {
    font-weight: 500;
    color: $n4; }

.location {
    @include caption-2;
    color: $n4; }

.scale {
    display: flex;
    justify-content: space-between;
    font-weight: 500; }
