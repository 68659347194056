@import "../../styles/helpers";

.destination {
    display: block;
    color: $n2;
    @include dark {
        color: $n8; }
    &:hover {
        .preview {
            img {
                transform: scale(1.1); } } } }

.preview {
    position: relative;
    overflow: hidden;
    margin-bottom: 20px;
    border-radius: 24px;
    &:before {
        content: "";
        display: block;
        padding-bottom: 72.7%;
        @include d {
            padding-bottom: 96%; }
        @include m {
            padding-bottom: 82%; } }
    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: transform 1s; } }

.category {
    position: absolute;
    top: 16px;
    left: 16px;
    z-index: 2; }

.title {
    margin-bottom: 8px;
    @include body-bold-1; }

.content {
    @include caption-2;
    color: $n4; }
