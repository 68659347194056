@import "../../../styles/helpers";

.section {
    @include d {
        margin-bottom: 96px; }
    @include m {
        margin-bottom: 64px; } }

.container {
    max-width: 1440px;
    @include w {
        padding: 0 40px; }
    @include t {
        padding: 0; }
    @include m {
        padding: 0 8px; } }

.preview {
    position: relative;
    &:before {
        content: "";
        display: block;
        padding-bottom: 60.63%;
        @include d {
            padding-bottom: 64%; }
        @include m {
            display: none; } }
    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 24px;
        object-fit: cover;
        @include t {
            border-radius: 0; }
        @include m {
            position: static;
            height: 700px;
            border-radius: 16px 16px 0 0;
            object-position: 50% 100%; } } }

.wrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 105px 80px 245px;
    text-align: center;
    @include d {
        padding: 64px 80px 200px; }
    @include t {
        padding: 80px 40px 180px; }
    @include m {
        display: block;
        padding: 48px 32px;
        text-align: center; } }

.title {
    margin-bottom: 16px;
    @include dark {
        color: $n2; } }

.info {
    margin-bottom: 24px;
    color: $n2;
    @include m {
        font-size: 24px; } }

.panel {
    margin: -175px 80px 0;
    @include w {
        margin: -175px 40px 0; }
    @include d {
        margin-top: -148px; }
    @include t {
        margin: -110px 16px 0; }
    @include m {
        margin: -205px 8px 0; } }

.body {
    margin-top: 32px;
    @include m {
        margin-top: 24px; } }

.controls {
    display: flex;
    margin-bottom: 16px;
    @include m {
        display: block; }
    .button {
        @include m {
            width: 100%; }
        &:not(:last-child) {
            margin-right: 16px;
            @include m {
                margin: 0 0 12px; } }
        &.active {
            box-shadow: inset 0 0 0 2px $n2;
            background: $n2;
            color: $n8;
            @include dark {
                box-shadow: inset 0 0 0 2px $n8;
                background: $n8;
                color: $n2; } } } }

.row {
    display: flex;
    padding-right: 100px;
    @include x {
        margin: 0 -20px; }
    @include t {
        margin: 0;
        padding-right: 80px; }
    @include m {
        display: block;
        padding: 0; }
    & > div {
        &:first-child {
            flex: 0 0 340px;
            @include t {
                flex: 0 0 270px; } }
        &:nth-child(2) {
            flex-grow: 1; } } }

.search {
    top: auto;
    bottom: 16px;
    transform: translateY(0);
    @include t {
        bottom: 0; }
    @include m {
        position: static;
        width: 100%;
        height: 48px;
        margin-top: 12px;
        border-radius: 24px; } }
