@import "../../styles/helpers";

.guests {
    position: relative;
    &.active {
        overflow: visible;
        z-index: 5;
        .button {
            svg {
                transform: rotate(180deg); } }
        .body {
            visibility: visible;
            opacity: 1; } } }

.button {
    min-width: 114px;
    svg {
        transition: all .2s; } }

.body {
    position: absolute;
    right: 0;
    left: 0;
    top: calc(100% + 4px);
    padding: 9px 6px 7px;
    border-radius: 24px;
    box-shadow: inset 0 0 0 2px $n6;
    background: $n8;
    visibility: hidden;
    opacity: 0;
    transition: all .2s;
    @include dark {
        background: $n2;
        box-shadow: inset 0 0 0 2px $n4; } }

.counter {
    width: 100%; }

