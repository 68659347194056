@import "../../../styles/helpers";

.line {
    display: flex;
    align-items: center;
    margin-bottom: 40px; }

.subtitle {
    margin-right: auto;
    @include body-bold-2; }

.fieldset {
    & > .field {
        margin-bottom: 32px; } }

.checkbox {
    margin-top: 32px; }

.button {
    margin-top: 32px; }

.logo {
    img {
        max-height: 24px; } }

.control {
    display: flex;
    align-items: center;
    .button {
        margin: 0 16px 0 0; } }

.verified {
    img {
        max-height: 48px; } }
