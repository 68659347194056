@import "../../../styles/helpers";

.control {
    margin-bottom: 48px;
    @include m {
        margin-bottom: 24px; } }

.head {
    display: flex;
    align-items: center;
    margin-bottom: 64px;
    @include m {
        display: block;
        margin-bottom: 32px; } }

.wrap {
    margin-right: auto; }

.title {
    margin-bottom: 12px; }

.counter {
    @include body-bold-2;
    color: $n4;
    @include m {
        margin-bottom: 32px; } }

.nav {
    display: flex;
    justify-content: center;
    margin-left: 32px;
    @include m {
        display: none; } }

.link {
    display: flex;
    padding: 6px 12px;
    border-radius: 14px;
    background: none;
    @include button-2;
    color: $n4;
    transition: all .2s;
    svg {
        margin-right: 8px;
        fill: $n4;
        transition: fill .2s; }
    &:hover {
        color: $n3;
        svg {
            fill: $n3; }
        @include dark {
            color: $n6; } }
    &.active {
        background: $n3;
        color: $n8;
        svg {
            fill: $n8; }
        @include dark {
            background: $n8;
            color: $n2; } }
    &:not(:last-child) {
        margin-right: 8px; } }

.list {
    display: flex;
    flex-wrap: wrap;
    margin: -30px -15px 0;
    @include m {
        display: block;
        margin: 0; } }

.card {
    flex: 0 0 calc(33.333% - 30px);
    width: calc(33.333% - 30px);
    margin: 30px 15px 0;
    @include t {
        flex: 0 0 calc(50% - 30px);
        width: calc(50% - 30px); }
    @include m {
        width: 100%;
        margin: 32px 0 0; } }

.flight {
    &:not(:last-child) {
        margin-bottom: 24px; } }

.btns {
    margin-top: 48px;
    text-align: center;
    @include m {
        margin-top: 32px; }
    .button {
        .loader {
            margin: 0 16px 0 2px;
            transform: scale(.8); } } }
