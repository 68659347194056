@import "../../styles/helpers";

.price {
    padding: 32px;
    border-radius: 24px;
    box-shadow: 0px 40px 32px -24px rgba(15, 15, 15, 0.12);
    border: 1px solid $n6;
    @include d {
        padding: 24px; }
    @include m {
        padding: 16px; }
    @include dark {
        border-color: $n3; } }

.description {
    margin-top: 32px;
    padding: 8px;
    border-radius: 20px;
    background: $n7;
    @include dark {
        background: $n2; }
    &.flex {
        display: flex;
        flex-wrap: wrap;
        @include m {
            display: block; }
        .item {
            flex: 0 0 50%;
            &:nth-child(2n) {
                position: relative;
                &:before {
                    content: "";
                    position: absolute;
                    top: 12px;
                    left: 0;
                    bottom: 12px;
                    width: 1px;
                    background: $n6;
                    @include dark {
                        background: $n4; }
                    @include m {
                        display: none; } } } } } }

.item {
    display: flex;
    padding: 12px; }

.icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    margin-right: 12px;
    svg {
        fill: $n5; } }

.category {
    @include caption-2;
    color: $n4; }

.subtitle {
    @include body-bold-2; }

.body {
    margin-top: 32px; }

.stage {
    margin-bottom: 24px;
    @include body-bold-1; }

.form {
    margin: -16px 0 16px; }

.row {
    display: flex;
    padding: 8px 12px;
    &:last-child {
        background: $n7;
        border-radius: 8px;
        @include dark {
            background: $n2; }
        .cell {
            &:first-child {
                color: $n2;
                @include dark {
                    color: $n8; } } } }
    &:not(:last-child) {
        margin-bottom: 4px; } }

.cell {
    &:first-child {
        margin-right: auto;
        color: $n4; }
    &:nth-child(2) {
        flex-shrink: 0;
        margin-left: 20px;
        text-align: right;
        font-weight: 500; } }

.note {
    margin-top: 32px;
    text-align: center;
    @include caption-2;
    color: $n4;
    svg {
        position: relative;
        top: -1px;
        margin-right: 8px;
        fill: $n4; } }
