@import "../../../styles/helpers";

.top {
    max-width: 640px;
    margin: 0 auto 80px;
    text-align: center;
    @include t {
        margin-bottom: 64px; }
    @include m {
        margin-bottom: 40px; } }

.stage {
    margin-bottom: 12px;
    @include hairline-2;
    color: $n4; }

.title {
    margin-bottom: 20px; }

.info {
    @include body-2;
    color: $n3;
    @include m {
        margin-bottom: 40px; }
    @include dark {
        color: $n6; }
    a {
        font-weight: 500;
        color: $p1;
        &:hover {
            text-decoration: underline; } } }

.dropdown {
    text-align: left; }

.row {
    display: flex;
    @include m {
        display: block; } }

.col {
    &:first-child {
        flex-shrink: 0;
        width: 160px;
        @include m {
            display: none; } }
    &:nth-child(2) {
        flex-grow: 1;
        padding-left: 225px;
        @include x {
            padding-left: 128px; }
        @include d {
            padding-left: 64px; }
        @include t {
            padding-left: 32px; }
        @include m {
            padding: 0; } } }

.nav {
    display: flex;
    flex-direction: column;
    align-items: flex-start; }

.link {
    display: flex;
    align-items: center;
    @include button-2;
    color: $n4;
    cursor: pointer;
    transition: color .2s;
    svg {
        margin-right: 16px;
        fill: $n4;
        transition: fill .2s; }
    &:hover,
    &.active {
        color: $n2;
        svg {
            fill: $n2; }
        @include dark {
            color: $n8;
            svg {
                fill: $n8; } } }
    &:not(:last-child) {
        margin-bottom: 40px; } }
