@import "../../styles/helpers";

.control {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    padding: 16px 0; }

.button {
    margin-right: auto;
    @include dark {
        box-shadow: inset 0 0 0 2px $n3;
        &:hover {
            background: $n3; } } }

.breadcrumbs {
    margin-left: 16px; }
