[class^="status"] {
  display: inline-block;
  padding: 0 12px;
  background: $n6;
  border-radius: 13px;
  font-size: 12px;
  line-height: 26px;
  font-weight: 700;
  text-transform: uppercase;
  color: $n3;
  @include dark-common {
    background: $n3;
    color: $n6; } }

.status-black {
  background: $n2;
  color: $n8;
  @include dark-common {
    background: $n8;
    box-shadow: 0px 8px 16px -8px rgba(15, 15, 15, 0.1);
    color: $n2; } }

.status-gray {
  background: $n7;
  color: $n4;
  @include dark-common {
    background: $n1;
    color: $n4; } }

