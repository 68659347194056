@import "../../styles/helpers";

.title {
    margin-bottom: 32px;
    padding-bottom: 32px;
    border-bottom: 1px solid $n6;
    @include dark {
        border-color: $n2; } }

.item {
    &:not(:first-child) {
        margin-top: 48px;
        padding-top: 48px;
        border-top: 1px solid $n6;
        @include dark {
            border-color: $n2; } } }

.category {
    @include body-bold-1; }

.box {
    & > .category {
        margin-bottom: 24px; }
    &:not(:last-child) {
        margin-bottom: 32px; } }

.top {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    & > .category {
        margin-right: auto; } }

.group {
    display: flex;
    margin: 0 -8px;
    @include m {
        display: block; } }

.option {
    position: relative;
    flex: 1;
    margin: 0 8px;
    padding: 8px 45px 8px 16px;
    border-radius: 12px;
    background: $n7;
    @include m {
        margin: 0; }
    @include dark {
        background: $n2; }
    &:not(:last-child) {
        @include m {
            margin-bottom: 8px; } } }

.info {
    @include caption-2;
    color: $n4; }

.input {
    display: none;
    width: 100%;
    height: 24px;
    background: none;
    @include poppins;
    @include body-bold-2;
    color: $n2; }

.edit {
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
    svg {
        fill: $n5;
        transition: fill .2s; }
    &:hover {
        svg {
            fill: $p1; } } }

.value {
    @include body-bold-2; }

.nav {
    display: flex;
    justify-content: center; }

.link {
    display: flex;
    padding: 6px 12px;
    border-radius: 14px;
    background: none;
    @include button-2;
    color: $n4;
    transition: all .2s;
    svg {
        margin-right: 8px;
        fill: $n4;
        transition: fill .2s; }
    &:hover {
        color: $n3;
        svg {
            fill: $n3; }
        @include dark {
            color: $n6; } }
    &.active {
        background: $n3;
        color: $n8;
        svg {
            fill: $n8; }
        @include dark {
            background: $n8;
            color: $n2; } }
    &:not(:last-child) {
        margin-right: 16px; } }

.label {
    margin-bottom: 12px;
    @include hairline-2;
    color: $n5; }

.line {
    display: flex;
    align-items: center;
    margin-bottom: 40px; }

.subtitle {
    margin-right: auto;
    @include body-bold-2; }

.cards {
    display: flex;
    align-items: center; }

.fieldset {
    & > .field {
        margin-bottom: 32px; } }

.row {
    display: flex;
    margin: 0 -8px;
    .field {
        flex: 0 0 calc(50% - 16px);
        width: calc(50% - 16px);
        margin: 0 8px; } }

.checkbox {
    margin-top: 32px; }

.message {
    margin-top: 24px;
    .category {
        margin-bottom: 24px; } }

.button {
    margin-top: 32px; }

.logo {
    img {
        max-height: 24px; } }

.control {
    display: flex;
    align-items: center;
    .button {
        margin: 0 16px 0 0; } }

.verified {
    img {
        max-height: 48px; } }
