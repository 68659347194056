@import "../../../../styles/helpers";

.title {
    margin-bottom: 8px; }

.profile {
    display: flex;
    align-items: center;
    margin-bottom: 32px;
    span {
        margin-right: 4px;
        color: $n4; } }

.avatar {
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    margin: 0 8px;
    img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover; } }

.name {
    @include body-bold-2; }

.options {
    display: flex;
    flex-wrap: wrap;
    margin: -32px -20px 0;
    @include m {
        display: block;
        margin: 0; } }

.option {
    display: flex;
    align-items: center;
    flex: 0 0 calc(50% - 40px);
    width: calc(50% - 40px);
    margin: 32px 20px 0;
    @include body-2;
    color: $n4;
    @include m {
        width: 100%;
        margin: 0;
        &:not(:last-child) {
            margin-bottom: 16px; } } }

.icon {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 48px;
    height: 48px;
    margin-right: 20px;
    border-radius: 50%;
    border-width: 2px;
    border-style: solid;
    @include d {
        margin-right: 12px; }
    svg {
        fill: $n2;
        @include dark {
            fill: $n8; } } }

.box {
    margin-top: 64px;
    padding-top: 64px;
    border-top: 1px solid $n6;
    @include d {
        margin-top: 48px;
        padding-top: 48px; }
    @include m {
        margin-top: 32px;
        padding-top: 32px; }
    @include dark {
        border-color: $n3; } }

.content {
    margin-bottom: 32px;
    @include body-2;
    h4 {
        margin-bottom: 32px;
        @include dm-sans;
        font-size: 32px;
        line-height: 40px; }
    p {
        color: $n4;
        &:not(:last-child) {
            margin-bottom: 24px; } } }


