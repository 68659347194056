@import "../../../styles/helpers";

.users {
    padding: 105px 0 0;
    box-shadow: inset -1px 0 0 0 $n6;
    @include x {
        padding-top: 80px; }
    @include dark {
        box-shadow: inset -1px 0 0 0 $n3; } }

.toggle {
    position: absolute;
    top: 48px;
    left: 48px;
    display: flex;
    align-items: center;
    @include body-bold-1;
    color: $n2;
    transition: color .2s;
    @include x {
        top: 24px;
        left: 32px; }
    @include d {
        left: 50%;
        transform: translateX(-50%);
        font-size: 0; }
    @include m {
        left: 32px;
        transform: translateX(0);
        font-size: 24px; }
    svg {
        margin-right: 8px;
        transition: fill .2s;
        @include d {
            margin-right: 0; }
        @include m {
            margin-right: 8px; } }
    @include dark {
        color: $n8;
        svg {
            fill: $n8; } }
    &:hover {
        color: $p1;
        svg {
            fill: $p1; } } }

.list {
    max-height: 100%;
    padding: 0 48px 48px;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    @include x {
        padding: 0 32px 32px; }
    @include d {
        display: flex;
        align-items: center;
        flex-direction: column; }
    @include m {
        display: block;
        padding: 0 32px 32px; } }
