@import "../../../styles/helpers";

.head {
    margin-bottom: 92px;
    text-align: center;
    @include m {
        margin-bottom: 40px; } }

.stage {
    margin-bottom: 8px; }

.title {
    margin-bottom: 32px; }

.wrapper {
    margin: 0 -16px;
    @include m {
        margin: 0 -4px; } }

