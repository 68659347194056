@import "../../../styles/helpers";

.title {
    margin-bottom: 48px;
    @include m {
        margin-bottom: 32px; } }

.list {
    display: flex;
    flex-wrap: wrap;
    margin: -32px -16px 0;
    @include m {
        display: block;
        margin: 0; } }

.card {
    flex: 0 0 calc(33.333% - 32px);
    width: calc(33.333% - 32px);
    margin: 32px 16px 0;
    @include t {
        flex: 0 0 calc(50% - 32px);
        width: calc(50% - 32px); }
    @include m {
        width: 100%;
        margin: 32px 0 0; } }

.btns {
    margin-top: 48px;
    text-align: center;
    @include m {
        margin-top: 32px; }
    .button {
        .loader {
            margin: 0 16px 0 2px;
            transform: scale(.8); } } }
