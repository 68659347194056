@import "../../styles/helpers";

.control {
    margin-bottom: 80px;
    @include t {
        margin-bottom: 48px; } }

.wrapper {
    display: flex;
    align-items: flex-start;
    @include t {
        flex-direction: column-reverse; } }

.confirm {
    flex-grow: 1;
    padding-right: 80px;
    @include x {
        padding-right: 48px; }
    @include d {
        padding-right: 32px; }
    @include t {
        width: 100%;
        padding: 0; } }

.price {
    flex-shrink: 0;
    width: 544px;
    @include x {
        width: 500px; }
    @include d {
        width: 416px; }
    @include t {
        width: 100%;
        margin-bottom: 48px; } }

