[class^="section"] {
    margin-bottom: 136px;
    @include d {
        margin-bottom: 112px; }
    @include m {
        margin-bottom: 64px; } }

.section-mb0 {
    margin-bottom: 0;
    @include d {
        margin-bottom: 0; }
    @include m {
        margin-bottom: 0; } }

.section-mb64 {
    margin-bottom: 64px;
    @include d {
        margin-bottom: 64px; } }

.section-mb80 {
    margin-bottom: 80px;
    @include d {
        margin-bottom: 64px; } }

.section-pd {
    padding: 136px 0;
    @include d {
        padding: 112px 0; }
    @include m {
        padding: 64px 0; } }

.section-bg {
    background: $n7;
    @include dark-common {
        background: none;
        box-shadow: inset 0 1px 0 $n2; } }
