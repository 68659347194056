@import "../../styles/helpers";

.inner {
    position: relative; }

.head {
    display: flex;
    align-items: center;
    margin-bottom: 48px;
    padding-right: 120px;
    @include t {
        flex-direction: column;
        align-items: flex-start; }
    @include m {
        margin-bottom: 32px;
        padding: 0; } }

.title {
    margin-right: 12px;
    @include m {
        font-size: 32px; } }

.dropdown {
    @include t {
        margin-left: -24px; } }

.wrapper {
    margin: 0 -16px;
    @include s {
        margin: 0 -8px; } }
