@import "../../styles/helpers";

.grid {
    display: flex;
    margin-left: -32px; }

.column {
    padding-left: 32px; }

.preview {
    margin-top: 32px;
    @include m {
        margin-top: 16px; }
    img {
        width: 100%;
        border-radius: 16px; } }

.foot {
    margin-top: 32px;
    text-align: center; }

