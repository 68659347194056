@import "../../../styles/helpers";

.dropdown {
    position: relative;
    &.active {
        .head {
            color: $n2;
            svg {
                transform: rotate(180deg);
                fill: $n2; }
            @include dark {
                color: $n6;
                svg {
                    fill: $n6; } } }
        .body {
            visibility: visible;
            opacity: 1;
            transform: translateY(0); } } }

.head {
    display: flex;
    align-items: center;
    @include button-2;
    color: $n4;
    transition: color .2s;
    @include m {
        display: none; }
    svg {
        margin-left: 8px;
        fill: $n4;
        transition: all .2s; }
    &:hover {
        color: $n2;
        svg {
            fill: $n2; }
        @include dark {
            color: $n6;
            svg {
                fill: $n6; } } } }

.body {
    position: absolute;
    top: calc(100% + 43px);
    left: -40px;
    width: 292px;
    padding: 16px;
    border-radius: 20px;
    box-shadow: 0px 40px 32px -8px rgba(15, 15, 15, 0.12);
    background: $n8;
    visibility: hidden;
    opacity: 0;
    transform: translateY(10px);
    transition: all .3s;
    @include m {
        position: static;
        width: auto;
        margin: 0 -32px;
        padding: 0;
        border-radius: 0;
        box-shadow: none;
        background: none;
        visibility: visible;
        transform: translateY(0);
        opacity: 1; }
    @include dark {
        background: $n1;
        box-shadow: 0px 10px 32px $n2; } }

.item {
    display: flex;
    align-items: center;
    height: 48px;
    padding: 0 20px;
    border-radius: 24px;
    @include button-2;
    color: $n4;
    transition: all .2s;
    @include m {
        height: 64px;
        padding: 0 32px;
        border-radius: 0;
        @include poppins;
        font-size: 24px;
        font-weight: 600; }
    svg {
        margin-right: 12px;
        fill: $n4;
        transition: fill .2s;
        @include m {
            display: none; } }
    &:hover {
        color: $n2;
        svg {
            fill: $n2; }
        @include dark {
            color: $n6;
            svg {
                fill: $n6; } } }
    &.active {
        background: $n2;
        color: $n6;
        @include m {
            background: none;
            box-shadow: inset 2px 0 0 $p1;
            color: $n2; }
        @include dark {
            background: $n8;
            color: $n3;
            @include m {
                background: none;
                color: $n6; } }
        svg {
            fill: $n6;
            @include dark {
                fill: $n3; } } } }
