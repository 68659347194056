@import "../../styles/helpers";

.nav {
    display: flex;
    justify-content: center;
    margin: 80px -16px 0;
    @include d {
        margin: 80px -8px 0; }
    @include t {
        justify-content: flex-start;
        margin: 80px -40px 0;
        overflow: auto;
        overflow-x: auto;
        -ms-overflow-style: none;
        scrollbar-width: none;
        -webkit-overflow-scrolling: touch;
        &::-webkit-scrollbar {
            display: none; }
        &:before,
        &:after {
            content: "";
            flex-shrink: 0;
            width: 40px;
            height: 1px; } }
    @include m {
        margin-top: 48px; } }

.link {
    position: relative;
    display: flex;
    align-items: center;
    flex: 0 0 calc(25% - 32px);
    width: calc(25% - 32px);
    margin: 0 16px;
    padding-bottom: 26px;
    cursor: pointer;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    @include d {
        flex: 0 0 calc(25% - 16px);
        width: calc(25% - 16px);
        margin: 0 8px; }
    @include t {
        flex: 0 0 256px;
        width: 256px;
        margin: 0; }
    &:after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 2px;
        border-radius: 2px;
        background: $n2;
        opacity: 0;
        transition: opacity .2s;
        @include dark {
            background: $n8; } }
    &:hover,
    &.active {
        &:after {
            opacity: 1; } }
    &:not(:last-child) {
        @include t {
            margin-right: 32px; } } }

.avatar {
    flex-shrink: 48px;
    width: 48px;
    height: 48px;
    margin-right: 16px;
    background: $n6;
    border-radius: 50%;
    overflow: hidden;
    img {
        width: 100%;
        height: 100%; } }

.man {
    margin-bottom: 4px;
    @include caption-1;
    font-weight: 500; }

.position {
    @include caption-1;
    color: $n4; }
