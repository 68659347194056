@import "../../styles/helpers";

.control {
    margin-bottom: 80px;
    @include t {
        margin-bottom: 64px; }
    @include m {
        margin-bottom: 24px; } }

.row {
    display: flex;
    align-items: flex-start;
    margin: 0 -16px;
    @include t {
        display: block;
        margin: 0; } }

.col {
    flex: 0 0 calc(50% - 32px);
    width: calc(50% - 32px);
    margin: 0 16px;
    @include t {
        width: 100%;
        margin: 0; }
    &:nth-child(2) {
        padding-left: 48px;
        @include d {
            padding-left: 0; } }
    &:not(:last-child) {
        @include t {
            margin-bottom: 48px; }
        @include m {
            margin-bottom: 32px; } } }

