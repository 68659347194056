@import "../../../styles/helpers";

.head {
    display: flex;
    align-items: center;
    margin-bottom: 32px;
    @include m {
        flex-direction: column-reverse;
        align-items: flex-start;
        margin-bottom: 24px; } }

.welcome {
    margin-right: auto;
    @include body-bold-1;
    @include m {
        margin: 24px 0 0; } }

.content {
    margin-bottom: 32px;
    color: $n4; }

.option {
    display: flex;
    align-items: flex-start;
    .text {
        font-weight: 500; }
    &:not(:last-child) {
        margin-bottom: 20px; } }

.category {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    width: 152px;
    padding-right: 24px;
    color: $n4;
    @include m {
        width: 130px; }
    svg {
        margin-right: 12px;
        fill: $n4; } }



