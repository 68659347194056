@import "../../../styles/helpers";

.list {
    display: flex;
    flex-wrap: wrap;
    margin: -32px -16px 0;
    @include m {
        display: block;
        margin: -32px 0 0; } }

.card {
    flex: 0 0 calc(50% - 32px);
    width: calc(50% - 32px);
    margin: 32px 16px 0;
    @include x {
        flex: 0 0 calc(33.333% - 32px);
        width: calc(33.333% - 32px); }
    @include t {
        flex: 0 0 calc(50% - 32px);
        width: calc(50% - 32px); }
    @include m {
        width: 100%;
        margin: 32px 0 0; }
    &:nth-child(n+7) {
        @include d {
            display: none; }
        @include t {
            display: block; } } }

.btns {
    margin-top: 64px;
    text-align: center;
    @include m {
        margin-top: 32px; }
    .button {
        .loader {
            margin: 0 16px 0 2px;
            transform: scale(.8); } } }
