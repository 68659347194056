@import "../../styles/helpers";

.inner {
    position: relative; }

.head {
    margin-bottom: 48px;
    padding-right: 120px;
    @include m {
        padding-right: 0; } }

.wrapper {
    margin: 0 -16px; }

.button {
    margin-top: 48px; }
