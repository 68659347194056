@import "../../styles/helpers";

.label {
    margin-bottom: 12px;
    @include hairline-2;
    color: $n5; }

.wrap {
    font-size: 0; }

.textarea {
    width: 100%;
    height: 140px;
    padding: 10px 14px;
    resize: none;
    border-radius: 12px;
    border: 2px solid $n6;
    background: none;
    @include poppins;
    @include caption-bold-1;
    color: $n2;
    transition: border-color .2s;
    @include dark {
        border-color: $n3;
        color: $n8; }
    @include placeholder {
        color: $n4; }
    &:focus {
        border-color: $n4; } }
