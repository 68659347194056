@import "../../styles/helpers";

.control {
    margin-bottom: 80px;
    @include t {
        margin-bottom: 48px; } }

.inner {
    display: flex;
    align-items: flex-start;
    @include t {
        display: block; } }

.wrapper {
    flex: 0 0 calc(100% - 352px);
    width: calc(100% - 352px);
    padding-right: 128px;
    @include x {
        padding-right: 64px; }
    @include d {
        flex: 0 0 calc(100% - 304px);
        width: calc(100% - 304px);
        padding-right: 32px; }
    @include t {
        width: 100%;
        padding: 0; } }

.preview {
    flex-shrink: 0;
    width: 352px;
    @include d {
        width: 304px; }
    @include t {
        display: flex;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 48px;
        background: rgba($n1,.9);
        overflow: auto;
        z-index: 999;
        visibility: hidden;
        opacity: 0;
        transition: all .4s;
        @include dark {
            background: rgba($n2,.9); }
        &.active {
            visibility: visible;
            opacity: 1; } }
    @include m {
        padding: 32px 16px; } }

.head {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
    @include d {
        flex-direction: column-reverse;
        align-items: flex-start; }
    @include m {
        margin-bottom: 32px; }
    .button {
        flex-shrink: 0;
        margin-left: 24px;
        @include d {
            margin: 0 0 16px; }
        @include dark {
            box-shadow: inset 0 0 0 2px $n3; } } }

.title {
    margin-right: auto;
    @include m {
        font-size: 32px; } }

.list {
    margin-bottom: 40px;
    padding-bottom: 40px;
    border-bottom: 1px solid $n6;
    @include m {
        margin-bottom: 32px;
        padding-bottom: 32px; }
    @include dark {
        border-color: $n3; } }

.item {
    &:not(:last-child) {
        margin-bottom: 40px;
        @include m {
            margin-bottom: 32px; } } }

.note {
    margin-top: 4px;
    @include caption-2;
    color: $n4; }

.file {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 182px;
    margin-top: 16px;
    border-radius: 16px;
    overflow: hidden;
    background: $n7;
    @include dark {
        background: $n2; } }

.load {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 400px;
    opacity: 0; }

.icon {
    margin-bottom: 10px;
    svg {
        fill: $n4; } }

.format {
    @include caption-2;
    color: $n4; }

.category {
    @include body-bold-2; }

.item {
    .button {
        @include dark {
            box-shadow: inset 0 0 0 2px $n3; } }
    &:not(:first-child) {
        .category {
            margin-bottom: 24px; } } }

.box {
    position: relative;
    .map {
        position: absolute;
        right: 16px;
        bottom: 16px;
        @include button-2;
        transition: color .2s;
        @include m {
            top: -4px;
            right: 0;
            bottom: auto; }
        @include dark {
            color: $n8; }
        &:hover {
            color: $p1; } } }


.fieldset {
    .field {
        &:not(:last-child) {
            margin-bottom: 24px;
            @include m {
                margin-bottom: 20px; } } } }

.label {
    margin-bottom: 12px;
    @include hairline-2;
    color: $n5; }

.row {
    display: flex;
    margin: 0 -10px;
    @include m {
        display: block;
        margin: 0; }
    &:not(:last-child) {
        margin-bottom: 24px;
        @include m {
            margin-bottom: 20px; } } }

.col {
    margin: 0 10px;
    &.w30 {
        flex: 0 0 calc(30% - 20px);
        width: calc(30% - 20px);
        @include m {
            width: 100%;
            margin: 0;
            &:not(:last-child) {
                margin-bottom: 20px; } } }
    &.w33 {
        flex: 0 0 calc(33.333% - 20px);
        width: calc(33.333% - 20px);
        @include m {
            width: 100%;
            margin: 0;
            &:not(:last-child) {
                margin-bottom: 20px; } } }
    &.w50 {
        flex: 0 0 calc(50% - 20px);
        width: calc(50% - 20px);
        @include m {
            width: 100%;
            margin: 0;
            &:not(:last-child) {
                margin-bottom: 20px; } } }
    &.w70 {
        flex: 0 0 calc(70% - 20px);
        width: calc(70% - 20px);
        @include m {
            width: 100%;
            margin: 0;
            &:not(:last-child) {
                margin-bottom: 20px; } } } }

.line {
    display: flex;
    height: 48px;
    box-shadow: inset 0 0 0 2px $n6;
    border-radius: 12px;
    align-items: center;
    @include dark {
        box-shadow: inset 0 0 0 2px $n3; } }

.cell {
    flex: 1;
    .field {
        width: 100%; }
    &:not(:last-child) {
        border-right: 1px solid $n6;
        @include dark {
            border-color: $n3; } } }


.foot {
    display: flex;
    align-items: center;
    @include m {
        display: block;
        text-align: center; }
    .button {
        @include m {
            width: 100%; }
        &:not(:last-child) {
            margin-right: 24px;
            @include m {
                margin: 0 0 12px; } } } }

.saving {
    display: inline-flex;
    align-items: center;
    margin-left: auto;
    @include button-1; }

.loader {
    margin-left: 24px; }

